@import 'node_modules/material-icons/iconfont/material-icons.css';

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

body,
html {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
  overflow-x: hidden;
}

.dark-mode {
  --bg-color: #191919 !important;
}

.static-tweet blockquote {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.static-tweet-emoji {
  height: 1.2em !important;
  width: 1.2em !important;
}

.notion-list-body {
  max-width: calc(var(--notion-max-width) - 4vw) !important;
}

.notion-list-item-body {
  overflow-x: visible !important;
}

.notion-aside-table-of-contents {
  max-width: 300px;
  padding: 0px 20px;
}

.notion-aside-table-of-contents a span {
  text-overflow: ellipsis;
  overflow: hidden;
}

.notion-list-item-title {
  /* make sure the title does not shrink to much */
  min-width: 150px !important;
}
.notion-column {
  display: block !important;
}

.notion-simple-table tr:first-child td {
  background-color: transparent !important;
}

.notion-simple-table tr td {
  border: 1px solid rgb(233, 233, 231) !important;
}

.simple-ink-button {
  border-radius: 8px;
  background-color: #0f93ff;
  color: #fff !important;
  font-weight: 500;
  padding: 10px 20px;
  text-decoration: none !important;
  border: 0;
  border-color: transparent !important;
}
.simple-ink-button:hover {
  background-color: #0e85e7;
}

.notion-page-title-text {
  white-space: normal;
}

.notion-collection {
  max-width: 100vw;
}
.notion-table-view {
  padding: 0px !important;
}
/* @media (max-width: 608px) {
} */

/* .notion-table::-webkit-scrollbar {
  display: none;
} */

.notion-collection-card-property {
  white-space: normal !important;
}

/* .notion-collection-card-property > .notion-property-relation {
  display: flex;
} */

.notion-collection-card-property .notion-page-title-text {
  white-space: normal !important;
}

.notion-collection-card-property .notion-page-title-icon {
  object-fit: cover;
}

.notion-page-icon-wrapper {
  justify-content: flex-start !important;
  left: auto !important;
  /* This is stop the the wrapper from overflowing. */
  max-width: 60%;
}

.notion-page-icon-wrapper {
  padding-left: 0px !important;
}

.notion-aside-table-of-contents {
  display: none;
}

.notion-h:hover .notion-hash-link {
  opacity: 0 !important;
}

.notion-collection-card {
  box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px,
    rgb(15 15 15 / 10%) 0px 2px 4px !important;
}

.custom-header-container {
  white-space: nowrap;
  position: sticky;
  top: 0px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 2;
}

.custom-header {
  display: flex;
  justify-content: space-between;
  height: 70px;
  padding: 0px 30px;
  align-items: center;
  z-index: 1;
}

.custom-header__title {
  font-weight: bold;
  z-index: 2;
  user-select: none;
}

.custom-header__menu {
  display: block;
  cursor: pointer;
  z-index: 2;
  user-select: none;
}

.custom-header__mobile-links {
  padding: 20px;
  padding-top: 70px;
  background-color: var(--bg-color);
  position: fixed;
  top: 0px;
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  left: 0px;
  z-index: 1;
  display: none;
}

.custom-header__mobile-links .custom-header__links__link {
  margin: 0px;
}

.custom-header__mobile-links .custom-header__links__link:last-child {
  margin-bottom: 0px;
}

.custom-header__mobile-links .custom-header__links__link > *:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.custom-header__links {
  display: none;
}

.custom-header__menu--open {
  display: none;
}

.menu-close {
  display: none;
}

.custom-header__menu--open + .menu-close {
  display: inherit;
}

.custom-header__mobile-links.custom-header__mobile-links--open {
  display: block;
}

.notion-block-9ead2d1fe7ba40d0bf56070e7cc602b1, .notion-block-7c114a4c1398444497f22c29c40d0b1a, .notion-block-f2f628ec20d94e15add021092772169a,
.notion-block-78b2eb59df694f939100366ad161837e,.notion-block-4bb3a9e71b374d2f9c299f6f455067e5
img{
  display: none !important;
}

.notion-page-title-text{
  white-space: normal !important;
  overflow: visible !important;
  line-height: normal !important;
}
.notion-page-link {
  height: auto !important;
}

.notion-page-title {
  line-height: normal !important;
}


@media (min-width: 900px) {
  .custom-header__menu {
    display: none;
  }

  .custom-header__links {
    display: flex;
  }

  .custom-header__mobile-links {
    display: none;
  }

  .custom-header__mobile-links.custom-header__mobile-links--open {
    display: none;
  }

  .custom-header__menu--open + .menu-close {
    display: none;
  }
}

.custom-header__links__link > * {
  padding: 10px 13px;
  margin-right: 10px;
  transition: 200ms ease all;
  border-radius: 8px;
  display: inline-block;
}

.custom-header__links__link {
  margin-right: 10px;
}

.custom-header__links__link .custom-search-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-header__links__link .custom-search-btn .searchIcon {
  height: 12px;
  display: inline-block;
  margin-right: 3px;
}

.custom-header__links__link:last-child {
  margin-right: 0px;
}

.custom-header__links__link > *:last-child {
  display: block;
  margin-right: 0px;
}

.custom-header__links__link a {
  text-decoration: none;
  cursor: pointer;
}

.custom-header__links__link > *:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.notion-table-header {
  z-index: 1 !important;
}

.notion-table-row * {
  text-align: left !important;
}

@media screen and (max-width: 899.99px) {
  .notion-frame {
    padding: 0 !important;
  }
}

@media (max-width: 800px) {
  .breadcrumbs {
    overflow: scroll;
    max-width: 75%;
  }
}

.notion-asset-wrapper img {
  height: auto !important;
  max-height: 100% !important;
}

.notion-calendar-body-dates-block-data b,
.notion-calendar-body-dates-block-data .notion-page-title-text {
  white-space: nowrap !important;
}

/* password protect */

.password-wrapper {
  width: 360px;
  margin: 0 auto;
  margin-top: 50px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.password-wrapper .header-1 {
  margin-top: 24px;
  margin-bottom: 24px;
  color: #111;
  font-size: 34px;
  line-height: 1.1;
  font-weight: 700;
}

.password-wrapper .label {
  display: block;
  font-size: 20px;
  color: rgb(17, 17, 17);
}

.password-wrapper .input {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: rgb(51, 51, 51);
  border: 1px solid #ccc;
  margin-bottom: 14px;
  border-radius: 3px;
  background-color: rgba(242, 241, 238, 0.6);
  outline: none;
}

.password-wrapper .html-embed-14 {
  display: flex;
  width: 80px;
  height: 80px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 120px;
  background-color: #f8ece9;
  color: #eb5757;
}

.password-wrapper .paragraph-3 {
  margin-bottom: 5px;
  color: rgba(55, 53, 47, 0.65);
  font-size: 14px;
}

.password-wrapper .paragraph-2 {
  margin-bottom: 24px;
  line-height: 1.5;
  color: rgb(0 0 0 / 59%);
  font-size: 16px;
}

.password-wrapper .submit {
  width: 100%;
  border-radius: 4px;
  background-color: #fdf5f2;
  box-shadow: 0 1px 2px 0 rgba(15, 15, 15, 0.1),
    0 0 0 1px rgba(235, 87, 87, 0.3);
  color: #eb5757;
  text-align: center;
  display: inline-block;
  padding: 9px 15px;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  background-color: rgb(253, 245, 242);
  -webkit-appearance: button;
  font-size: 16px;
}

.password-wrapper .submit:hover {
  background-color: rgb(248, 236, 233);
}

.password-wrapper .remember-me-wrapper {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
  margin-top: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.password-wrapper .remember-me-wrapper:hover {
  cursor: pointer;
}

.password-wrapper .tickbox-notion {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  border: 1px solid #f64f59;
  border-radius: 3px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
}

.password-wrapper .tickbox-notion--active {
  background-color: #f64f59;
}

blockquote .notion-page-title {
  width: auto !important;
}

/* .notion-calendar-modal {
  position: fixed !important;
}

.notion-calendar-modal-header .notion-calendar-header-controls .current-day {
  white-space: nowrap;
} */

.notion-calendar-modal {
  --shadow-color: rgb(55 53 47 / 9%);
  --body-color: #fbfbfa;
  --header-color: #fff;
  background-color: var(--body-color) !important;
}

.dark-mode.notion-calendar-modal {
  --shadow-color: rgb(255 255 255 / 9%);
  --body-color: var(--bg-color);
  --header-color: var(--fg-color-0);
}

.notion-calendar-modal-header {
  background-color: var(--header-color) !important;
  box-shadow: var(--shadow-color) 0px 1px 0px !important;
}

.notion-calendar-modal-body {
  box-shadow: var(--shadow-color) 0px -1px 0px !important;
}

.notion-calendar-modal-body .notion-collection-card {
  box-shadow: var(--shadow-color) 0px 1px 0px !important;
  padding-right: 10px;
  padding-left: 10px;
}

.notion-calendar-modal-body .notion-page-title-text {
  font-weight: bold;
}

.notion-external-dropbox iframe {
  width: 100%;
}


.notion-block-c8895dd91af34b34a18f41dcac117034 span.notion-property.notion-property-relation {
  display: flex ;
  flex-wrap: wrap !important ;

}

.notion-block-c8895dd91af34b34a18f41dcac117034 span.notion-property.notion-property-relation .notion-page-title {
  min-width: inherit ;
}

.notion-page-content-inner video {
width:100%;
}

blockquote.notion-quote {
  font-size: medium;
}

.notion-block-18444bc3f6b481b1b15cf8022504e48a .notion-collection-card-cover {
  height: auto !important;
}

.notion-block-195107cb2e1180498564e7cc23bea77d .notion-row .remove_underline {
  display: flex;
  align-items: center;
  align-items: stretch;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.13);
}
.notion-block-195107cb2e1180498564e7cc23bea77d .notion-row .remove_underline:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.dark-mode.notion-block-195107cb2e1180498564e7cc23bea77d .notion-row .remove_underline:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.dark-mode.notion-block-195107cb2e1180498564e7cc23bea77d .notion-row .remove_underline {
  border: 1px solid rgba(255, 255, 255, 0.13);
}

.notion-block-195107cb2e1180498564e7cc23bea77d .notion-row .remove_underline > div:nth-child(1) {
  flex: 4 1 180px;
  padding: 12px 14px 14px;
}

.notion-block-195107cb2e1180498564e7cc23bea77d .notion-row .remove_underline > div:nth-child(2) {
  flex: 1 1 180px;
}

/*-----*/